import axios from "axios";
import React, { useState } from "react";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send form data to backend
      const response = await axios.post(
        `${apiUrl}/emails/support-email`,
        formData
      );

      if (response.status === 200) {
        setStatusMessage("Viesti lähetetty onnistuneesti.");
      } else {
        setStatusMessage("Jokin meni pieleen. Yritä uudelleen.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setStatusMessage("Jokin meni pieleen. Yritä uudelleen.");
    }
  };

  return (
    <div className="min-h-screen p-2 lg:p-4 2xl:p-8 bg-transparent flex justify-center">
      <div className="w-full h-1/2 m-4 sm:m-12 sm:w-5/6 md:w-4/6 lg:w-1/2 xl:w-2/5 3xl:w-1/5 py-4 sm:py-4 md:py-4 lg:py-6 bg-white p-12 rounded-3xl shadow-lg">
        <h1 className="text-lg xl:text-xl 2xl:text-2xl font-bold mb-8 text-center">
          Yhteydenottolomake
        </h1>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="name"
              className="block text-sm lg:text-md 2xl:text-lg font-semibold"
            >
              Nimi
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full p-0 xl:p-1 border border-gray-300 rounded-lg focus:border-teal-600 focus:outline-none"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm lg:text-md 2xl:text-lg font-semibold"
            >
              Sähköposti
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full p-1 xl:p-1 border border-gray-300 rounded-lg focus:border-teal-600 focus:outline-none"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-sm lg:text-md 2xl:text-lg font-semibold"
            >
              Puhelinnumero
            </label>
            <input
              type="phone"
              id="phone"
              name="phone"
              className="w-full p-1 xl:p-1 border border-gray-300 rounded-lg focus:border-teal-600 focus:outline-none"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-sm lg:text-md 2xl:text-lg font-semibold"
            >
              Viesti
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full p-1 xl:p-1 border border-gray-300 rounded-lg focus:border-teal-600 focus:outline-none"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="flex w-3/4 lg:w-1/2 justify-center rounded-3xl bg-teal-600 px-5 py-3 text-sm lg:text-md 2xl:text-lg lg:text-lg font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
            >
              Lähetä viesti
            </button>
          </div>
        </form>
        {statusMessage && (
          <div className="mt-4 text-center text-red-600">{statusMessage}</div>
        )}
      </div>
    </div>
  );
};

export default Support;
