import React, { useEffect } from "react";
import remove from "../assets/cross-circle.svg"; // Replace with your close icon

const TermsModal = ({ isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-xl p-4 w-full m-2 lg:w-1/2 h-3/4 max-h-[80vh] relative overflow-y-auto">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <img src={remove} alt="close" className="w-6" />
        </button>
        <div className="overflow-y-auto p-1 lg:p-6">
          <h1 className="text-2xl font-bold mb-8 ">
            Käyttöehdot ja lomakrediittien määräytyminen
          </h1>
          <div className="space-y-8 max-w-4xl mx-auto">
            <section className="p-1  bg-white rounded-lg">
              <p className="text-lg">
                Vaihtolomat.fi tarjoaa suomalaisille viikko-osakkeen omistajille
                vastaavan palvelun kuin kansainvälinen RCI, mutta keskittyen
                kotimaisiin lomakohteisiin. Meillä käytät pisteiden sijaan
                lomakrediittejä, joilla voit joustavasti varailla uusia
                lomakohteita ympäri Suomen.
              </p>
            </section>
            <section className="p-1  lg:p-6 bg-white rounded-lg">
              <p className="text-lg">
                Nämä käyttöehdot ("Ehdot") koskevat vaihtolomat.fi -verkkosivun
                ("Sivusto") käyttöä. Sivusto on tarkoitettu viikko-osakkeiden
                omistajille hallita ja jakaa hallitsemiaan viikkoja. Käyttäjien
                tulee hyväksyä nämä Ehdot rekisteröityessään Sivustolle ja
                käyttääkseen Sivuston palveluita.
              </p>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">1. Palvelun kuvaus</h2>
              <p className="text-lg">
                Sivuston kautta tarjottavat palvelut ja toiminnot mahdollistavat
                käyttäjien tallettaa hallitsemiaan viikkoja sekä vastaanottaa
                krediittejä, jotka perustuvat talletetun viikon huoneiston
                kokoon ja ajankohtaan. Näillä krediiteillä käyttäjät voivat
                vastaavasti varata vapaana olevia muiden tallettamia viikkoja
                hinnaston mukaisesti.
              </p>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">
                2. Alustan tarjoaminen
              </h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Palvelun tuottaja/omistaja toimii ainoastaan välittäjänä ja
                  tarjoaa teknisen alustan viikko-osakkeiden hallintaa varten.
                  Emme osallistu viikkojen vaihtoon, hallinnointiin tai jakoon
                  liittyvien sopimusten solmimiseen tai täytäntöönpanoon.
                </li>
                <li>
                  Käyttäjät vastaavat itse kaikista velvollisuuksista ja
                  sitoumuksista, jotka liittyvät viikko-osakkeiden hallintaan ja
                  jakamiseen.
                </li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">
                3. Lomaviikon varaus
              </h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Lomaviikon tallettaja eli omistaja on velvollinen ilmoittamaan
                  avainpalveluun lomailijan nimen, kun palvelusta varataan
                  vapaana oleva lomaviikko. Varaajan yhteystiedot menevät
                  automaattisesti sähköpostiviestillä kyseisen osakkeen
                  omistajalle, joka ilmoittaa 2 vrk:n kuluessa avainpalveluun
                  lomailijan tiedot.
                </li>
                <li>
                  Lisäksi lomaviikon varaajan sähköpostiin menee lomaviikon
                  omistajan yhteystiedot. Sen jälkeen lomaviikon omistaja käy
                  vaihtolomat.fi sivulla vahvistamassa, että varaus on tehty
                  lomaviikon varaajan nimiin ja avainpalveluun on ilmoitettu
                  saapujan yhteystiedot.
                </li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">
                4. Käyttäjien vastuut
              </h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Käyttäjien tulee käyttää Sivustoa lainmukaisesti ja noudattaa
                  kaikkia sovellettavia sääntöjä ja määräyksiä.
                </li>
                <li>
                  Käyttäjien tulee varmistaa, että kaikki Sivustolle talletetut
                  tiedot ovat tarkkoja ja ajan tasalla.
                </li>
                <li>
                  Viikon talletuksen tehnyt omistaja sitoutuu antamaan
                  pyydettäessä lisätietoja kohteesta ja ilmoittamaan
                  avainpalveluun sen vuokraajan nimen, joka on omilla
                  krediiteillään varannut kyseisen viikon palvelun kautta.
                </li>
                <li>
                  Sivustolle ilmoitettujen vapaiden viikkojen tulee olla
                  käytettävissä kyseisenä ajankohtana, eikä niitä saa olla
                  talletettu muihin vaihtopalveluihin, kuten RCI, eikä niitä saa
                  olla liitettynä pistejärjestelmään.
                </li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">
                5. Käyttöehtojen muutokset
              </h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Palvelun tuottaja/omistaja pidättää oikeuden muuttaa näitä
                  käyttöehtoja tarvittaessa. Muutokset astuvat voimaan, kun ne
                  on julkaistu Sivustolla.
                </li>
                <li>
                  Käyttäjien tulee hyväksyä nämä ehdot rekisteröityessään
                  Sivustolle ja käyttääkseen Sivuston palveluita.
                </li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">
                6. Krediittien määräytyminen
              </h2>
              <p className="text-lg">
                Krediittien määrä viikkoa kohden perustuu kohteen kysyntään,
                käyttöasteeseen sekä huoneiston nukkumapaikkojen määrään. Näiden
                tekijöiden perusteella määräytyy kunkin kohteen krediittiarvo.
                Krediittilaskurilla voit tarkistaa omistamasi osakkeen
                krediittiarvon. Lisäksi krediittejä voi saada enemmän tai
                vähemmän riippuen viikon tallettamisajankohdasta:
              </p>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Krediittejä saa 20 % enemmän laskurin mukaisesta määrästä, jos
                  viikon tallettaa viimeistään 10 kuukautta ennen kyseisen
                  viikon alkamista.
                </li>
                <li>
                  Krediittejä saa laskurin mukaisen määrän, jos viikon tallettaa
                  viimeistään 3 kuukautta ennen kyseisen viikon alkamista.
                </li>
                <li>
                  Krediittejä saa 50% laskurin mukaisesta krediittimäärästä, jos
                  viikon tallettaa kolmen kuukauden sisällä ennen kyseisen
                  viikon alkamista.
                </li>
                <li>
                  Krediittejä saa 30 % laskurin mukaisesta krediittimäärästä,
                  jos viikon tallettaa neljän viikon sisällä ennen kyseisen
                  viikon alkamista.
                </li>
                <li>
                  Viikon tallettamista ei voi tehdä, jos aikaa on jäljellä 7
                  päivää tai vähemmän kyseisen viikon alkamiseen. Ilmoitukset
                  poistuvat automaattisesti sivuilta, kun 2 päivää on jäljellä
                  loman alkamiseen, jotta ilmoittajalle jää riittävästi aikaa
                  ottaa yhteyttä avainpalveluun.
                </li>
              </ul>
              <p className="text-lg">
                Talletetuista viikoista saadut krediitit nollautuvat ja
                poistuvat lompakosta automaattisesti, mikäli niitä ei ole
                käytetty 24 kuukauden sisällä talletuksesta.
              </p>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">7. Osakkeen myynti</h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Jos osakkeen omistaja myy osakkeensa, kaikki lompakossa olevat
                  krediitit on mahdollista siirtää uudelle omistajalle
                  pyydettäessä. Tässä tapauksessa uuden omistajan on
                  rekisteröidyttävä palvelun käyttäjäksi ja maksettava
                  jäsenmaksu.
                </li>
                <li>
                  Kun viikko on talletettu, omistaja luopuu hallintaoikeudestaan
                  kyseiseen viikkoon. Mikäli omistaja haluaa viikon takaisin
                  itselleen, talletuksen voi purkaa maksamalla vaihtomaksun.
                </li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">
                8. Talletuksen purku
              </h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Jos talletuksen purkaa, on maksettava vaihtomaksu ja
                  lompakosta vähennetään krediittejä lomaviikon
                  krediittilaskurin mukainen määrä.
                </li>
                <li>
                  Jos lompakossa ei ole tarpeeksi krediittejä, on niitä ensin
                  ostettava riittävästi, jonka jälkeen talletuksen voi purkaa.
                </li>
                <li>
                  Jos joku on varannut kyseisen lomaviikon, talletusta ei voi
                  enää purkaa.
                </li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">9. Maksut</h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana
                  toimii Paytrail Oyj (2122839-7) yhteistyössä suomalaisten
                  pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun
                  saajana tiliotteella tai korttilaskulla ja välittää maksun
                  kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa.
                  Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti
                  yhteyttä tuotteen toimittajaan. Paytrail Oyj, y-tunnus:
                  2122839-7 Innova 2 Lutakonaukio 7 40100 Jyväskylä
                  paytrail.com/kuluttaja/tietoa-maksamisesta
                </li>
                <li>Sivustolla olevilla maksuilla ei ole palautusoikeutta.</li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">
                Vastuuvapauslauseke
              </h2>
              <p className="text-lg">
                Tämä verkkosivu ("vaihtolomat.fi") on tarkoitettu
                viikko-osakkeiden omistajille hallita ja jakaa hallitsemiaan
                viikkoja. Palvelun tuottaja/omistaja toimii ainoastaan
                välittäjänä ja tarjoaa teknisen alustan viikko-osakkeiden
                hallintaa varten. Palvelun tuottaja/omistaja ei osallistu
                viikkojen vaihtoon, hallinnointiin tai jakoon liittyvien
                sopimusten solmimiseen tai täytäntöönpanoon.
              </p>
              <ul className="list-disc list-inside space-y-2 text-lg mt-4">
                <li>
                  Palvelun tuottaja/omistaja ei ole vastuussa mistään suorista,
                  epäsuorista, satunnaisista, erityisistä tai välillisistä
                  vahingoista, jotka johtuvat Sivuston käytöstä tai
                  kyvyttömyydestä käyttää Sivustoa.
                </li>
                <li>
                  Emme takaa Sivuston keskeytyksetöntä tai virheetöntä
                  toimintaa, emmekä ota vastuuta mahdollisista teknisistä
                  ongelmista tai tietoturvaan liittyvistä riskeistä.
                </li>
                <li>
                  Palvelun tuottaja/omistaja ei ole vastuussa mistään
                  lomahuoneistojen käyttöön liittyvistä asioista tai
                  varausprosessista johtuvista ongelmista.
                </li>
                <li>
                  Kaikki lomahuoneistojen varaamiseen liittyvät sopimukset
                  tehdään suoraan huoneiston omistajan/hallitsijan ja vuokraajan
                  välillä, eikä palvelun tuottaja/omistaja osallistu näihin
                  sopimuksiin.
                </li>
                <li>
                  Palvelun tuottaja/omistaja ei ole vastuussa mistään
                  lomahuoneistossa loman aikana sattuneista vahingoista tai
                  ongelmista.
                </li>
              </ul>
            </section>

            <section className="p-1  lg:p-6 bg-white  rounded-lg">
              <h2 className="text-xl font-semibold my-2">Tietosuojaseloste</h2>
              <h3 className="text-lg font-semibold my-2">Johdanto</h3>
              <p className="text-lg">
                Tämä tietosuojaseloste kuvaa, kuinka vaihtolomat.fi (”Sivusto”)
                kerää, käyttää, säilyttää ja suojaa käyttäjiensä henkilötietoja.
                Sivuston omistaja ja ylläpitäjä on Lomahuuto.fi Oy (”Palvelun
                tuottaja/omistaja”), Veromaankatu 4, 05830 Hyvinkää, y-tunnus
                3405697-7. Palvelun tuottaja/omistaja sitoutuu suojaamaan
                käyttäjiensä yksityisyyttä ja henkilötietoja GDPR:n mukaisesti.
              </p>

              <h3 className="text-lg font-semibold my-2">
                1. Kerättävät tiedot
              </h3>
              <p className="text-lg">
                Sivustolla kerätään käyttäjistä seuraavia tietoja:
              </p>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Henkilökohtaiset tunnistetiedot: Nimi, sähköpostiosoite,
                  puhelinnumero, postiosoite ja muut rekisteröinnin yhteydessä
                  annetut tiedot.
                </li>
                <li>
                  Käyttötiedot: Tiedot Sivuston käytöstä, kuten vierailuaika,
                  vieraillut sivut ja muut vastaavat tiedot.
                </li>
                <li>
                  Tekniset tiedot: IP-osoite, selaimen tyyppi ja versio,
                  käyttöjärjestelmä ja muut vastaavat tekniset tiedot.
                </li>
              </ul>

              <h3 className="text-lg font-semibold my-2">
                2. Tietojen kerääminen
              </h3>
              <p className="text-lg">
                Keräämme tietoja suoraan käyttäjiltä seuraavilla tavoilla:
              </p>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>Käyttäjän rekisteröityessä Sivustolle.</li>
                <li>
                  Käyttäjän täyttäessä lomakkeita tai päivittäessä tietojaan
                  Sivustolla.
                </li>
                <li>Evästeiden avulla, kun käyttäjä käyttää Sivustoa.</li>
              </ul>

              <h3 className="text-lg font-semibold my-2">3. Tietojen käyttö</h3>
              <p className="text-lg">
                Keräämiämme tietoja käytetään seuraaviin tarkoituksiin:
              </p>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Palvelun tarjoaminen: Käyttäjien tunnistaminen, käyttäjätilien
                  hallinta ja Sivuston toimintojen tarjoaminen.
                </li>
                <li>
                  Asiakaspalvelu: Käyttäjien tukeminen ja heidän kysymyksiinsä
                  vastaaminen.
                </li>
                <li>
                  Analysointi ja parantaminen: Sivuston käytön analysointi ja
                  palvelun parantaminen.
                </li>
                <li>
                  Markkinointi: Käyttäjien tiedottaminen uusista
                  ominaisuuksista, tarjouksista ja muista uutisista. Käyttäjille
                  ja kumppaneille on oikeus lähettää markkinointiviestejä, jotka
                  koskevat lomaosakkeisiin liittyviä palveluita tai tuotteita.
                </li>
              </ul>

              <h3 className="text-lg font-semibold my-2">
                4. Tietojen jakaminen
              </h3>
              <p className="text-lg">
                Kerättyjä tietoja voidaan jakaa seuraavissa tilanteissa:
              </p>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Palveluntarjoajat: Luotettavat kolmannet osapuolet, jotka
                  auttavat meitä palvelumme tarjoamisessa, kuten
                  maksupalveluntarjoajat ja IT-palveluntarjoajat.
                </li>
                <li>
                  Lakisääteiset vaatimukset: Tietojen luovuttaminen
                  viranomaisille, jos laki niin vaatii.
                </li>
                <li>
                  Yritysjärjestelyt: Jos liiketoimintamme myydään tai
                  yhdistetään toiseen yritykseen, käyttäjien tiedot voidaan
                  siirtää osana liiketoimintaa.
                </li>
              </ul>

              <h3 className="text-lg font-semibold my-2">
                5. Tietojen säilyttäminen
              </h3>
              <p className="text-lg">
                Säilytämme käyttäjien henkilötietoja niin kauan kuin on tarpeen
                niiden tarkoitusten toteuttamiseksi, joihin tiedot on kerätty,
                tai niin kauan kuin laki vaatii. Kun tiedot eivät ole enää
                tarpeen, ne poistetaan turvallisesti.
              </p>

              <h3 className="text-lg font-semibold my-2">
                6. Käyttäjien oikeudet
              </h3>
              <p className="text-lg">
                Käyttäjillä on seuraavat oikeudet omiin henkilötietoihinsa:
              </p>
              <ul className="list-disc list-inside space-y-2 text-lg">
                <li>
                  Oikeus saada pääsy tietoihin: Käyttäjillä on oikeus saada
                  tietoa siitä, mitä henkilötietoja heistä on kerätty.
                </li>
                <li>
                  Oikeus tietojen oikaisemiseen: Käyttäjillä on oikeus pyytää
                  virheellisten tai puutteellisten tietojen korjaamista.
                </li>
                <li>
                  Oikeus tietojen poistamiseen: Käyttäjillä on oikeus pyytää
                  tietojensa poistamista, jos niiden säilyttämiselle ei ole enää
                  perusteita.
                </li>
                <li>
                  Oikeus käsittelyn rajoittamiseen: Käyttäjillä on oikeus pyytää
                  henkilötietojensa käsittelyn rajoittamista tietyissä
                  tilanteissa.
                </li>
                <li>
                  Oikeus vastustaa käsittelyä: Käyttäjillä on oikeus vastustaa
                  henkilötietojensa käsittelyä tietyissä tilanteissa.
                </li>
                <li>
                  Oikeus tietojen siirrettävyyteen: Käyttäjillä on oikeus saada
                  heistä kerätyt henkilötiedot jäsennellyssä, yleisesti
                  käytetyssä ja koneellisesti luettavassa muodossa.
                </li>
              </ul>

              <h3 className="text-lg font-semibold my-2">
                7. Tietojen suojaaminen
              </h3>
              <p className="text-lg">
                Palvelun tuottaja/omistaja käyttää asianmukaisia teknisiä ja
                organisatorisia toimenpiteitä suojatakseen käyttäjien
                henkilötietoja luvattomalta pääsyltä, muutoksilta,
                paljastamiselta tai tuhoamiselta. Näitä toimenpiteitä ovat muun
                muassa tietojen salaus, palomuurit ja turvalliset palvelimet.
              </p>

              <h3 className="text-lg font-semibold my-2">8. Evästeet</h3>
              <p className="text-lg">
                Sivusto käyttää evästeitä parantaakseen käyttäjäkokemusta ja
                analysoidakseen Sivuston käyttöä. Käyttäjät voivat hallita
                evästeasetuksiaan selaimen asetuksista.
              </p>

              <h3 className="text-lg font-semibold my-2">
                9. Muutokset tietosuojaselosteeseen
              </h3>
              <p className="text-lg">
                Palvelun tuottaja/omistaja pidättää oikeuden muuttaa tätä
                tietosuojaselostetta tarvittaessa. Muutokset astuvat voimaan,
                kun ne on julkaistu Sivustolla. Käyttäjiä suositellaan
                tarkistamaan tietosuojaseloste säännöllisesti mahdollisten
                muutosten varalta.
              </p>

              <h3 className="text-lg font-semibold my-2">10. Yhteystiedot</h3>
              <p className="text-lg">
                Jos sinulla on kysymyksiä tästä tietosuojaselosteesta tai haluat
                käyttää oikeuksiasi, ota yhteyttä asiakaspalveluumme:
              </p>
              <p className="text-lg mt-2">
                Lomahuuto.fi Oy
                <br />
                Veromaankatu 4
                <br />
                05830 Hyvinkää
                <br />
                y-tunnus 3405697-7
                <br />
                Sähköpostiosoite: asiakaspalvelu@lomahuuto.fi
                <br />
                Puhelinnumero: 050 5056448
                <br />
                Tämä tietosuojaseloste päivitettiin viimeksi [26.5.2024].
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
