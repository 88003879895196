import React, { useState, useEffect } from "react";
import TimeshareInfoInput from "../components/TimeshareInfoInput";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Modal from "../components/Modal";
import TermsModal from "../components/TermsModal";

const Register = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rciBlocked, setRciBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showTimeshareForm, setShowTimeshareForm] = useState(false);
  const [timeshareInput, setTimeshareInput] = useState({
    place: "",
    property: "",
    startDate: "",
    endDate: "",
    rooms: "",
    sleeping: "",
    room: "",
    rci: "",
    sauna: false,
    balcony_terrace: false,
    pets: false,
  });
  const [acceptTerms, setAcceptTerms] = useState(false); // State for terms acceptance checkbox
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    acceptTerms: "", // Error for the checkbox
    general: "",
  });

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log(
      "Register component rendered. showTimeshareForm:",
      showTimeshareForm
    );
  }, [showTimeshareForm]);

  const updateTimeshare = (updatedTimeshare) => {
    setTimeshareInput((prev) => ({
      ...prev,
      ...updatedTimeshare,
      sauna: updatedTimeshare.sauna ? 1 : 0,
      balcony_terrace: updatedTimeshare.balcony_terrace ? 1 : 0,
      pets: updatedTimeshare.pets ? 1 : 0,
      rci: updatedTimeshare.rci ? 1 : 0, // RCI as boolean
    }));
  };

  const handleSubmit = async (e) => {
    console.log("submit");

    e.preventDefault();

    let valid = true;
    const newErrors = {
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      general: "",
      timeshare: "",
      acceptTerms: "", // Add error message for terms acceptance
    };

    // Check for empty fields in the main inputs
    if (!email) {
      newErrors.email = "Sähköpostiosoite ei voi olla tyhjä";
      valid = false;
    }
    if (!password) {
      newErrors.password = "Salasana ei voi olla tyhjä";
      valid = false;
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = "Vahvista salasanasi";
      valid = false;
    }
    if (!phone) {
      newErrors.phone = "Puhelinnumero ei voi olla tyhjä";
      valid = false;
    }
    if (!acceptTerms) {
      newErrors.acceptTerms = "Sinun tulee hyväksyä käyttöehdot.";
      valid = false;
    }

    // Check for empty fields in timeshareInput
    const requiredTimeshareFields = [
      "startDate",
      "endDate",
      "place",
      "property",
      "sleeping",
    ];
    requiredTimeshareFields.forEach((field) => {
      if (!timeshareInput[field]) {
        newErrors.timeshare = "Kaikki osakkeen kentät tulee täyttää";
        valid = false;
      }
    });

    // Validation checks
    if (email && !isValidEmail(email)) {
      newErrors.email = "Virheellinen sähköpostiosoite";
      valid = false;
    }

    if (password && !isValidPassword(password)) {
      newErrors.password = "Salasanan tulee olla vähintään 8 merkkiä pitkä";
      valid = false;
    }

    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = "Salasanat eivät täsmää";
      valid = false;
    }

    if (phone && !isValidPhone(phone)) {
      newErrors.phone = "Virheellinen puhelinnumero";
      valid = false;
    }

    setErrors(newErrors);

    console.log("Validation result:", valid);
    if (!valid) return; // Stop form submission if validation fails

    // Format timeshare data
    const startDate = new Date(timeshareInput.startDate);
    const endDate = new Date(timeshareInput.endDate);

    const formattedTimeshare = {
      ...timeshareInput,
      startDate: isValidDate(startDate)
        ? startDate.toISOString().slice(0, 10)
        : null,
      endDate: isValidDate(endDate) ? endDate.toISOString().slice(0, 10) : null,
      sleeping: parseInt(timeshareInput.sleeping),
      sauna: timeshareInput.sauna ? 1 : 0,
      balcony_terrace: timeshareInput.balcony_terrace ? 1 : 0,
      pets: timeshareInput.pets ? 1 : 0,
    };

    const data = {
      email,
      password,
      phone,
      timeshare: formattedTimeshare,
    };

    console.log("API URL:", apiUrl);
    console.log("Data payload:", data);

    try {
      const response = await axios.post(`${apiUrl}/auth/register`, data);
      console.log(response.data);

      if (response.status === 201) {
        console.log("User registered successfully");
        const { userId } = response.data;

        // Redirect to Telia identification service
        const clientId = "bdca319b-1568-4002-95c3-74b89882cc70"; // Replace with Telia-provided client ID
        const redirectUri = "https://vaihtolomat.fi/api/tunnistaudu"; // Your backend route
        const state = JSON.stringify({
          userId: userId,
          email: email,
          randomState: Math.random().toString(36).substring(2),
        });

        const identificationUrl = `https://tunnistus.telia.fi/uas/oauth2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid&state=${encodeURIComponent(
          state
        )}`;

        window.location.href = identificationUrl;
      }
    } catch (error) {
      console.error("Error during registration request:", error);
      if (error.response) {
        console.error("Error response:", error.response);
        setErrors((prevErrors) => ({
          ...prevErrors,
          general:
            error.response.data.message ||
            "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
        }));
      }
    }
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^(\+?\d{1,3}[- ]?)?(\d{9,15})$/.test(phone);
  };

  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  const handleShowForm = () => {
    setShowTimeshareForm((prev) => {
      return !prev;
    });
  };

  const handleOpenTerms = () => setShowTermsModal(true);
  const handleCloseTerms = () => setShowTermsModal(false);

  return (
    <div className="bg-transparent min-h-screen p-4 py-8">
      <div className="flex justify-center items-center h-full">
        <div className="w-full md:m-12 sm:w-full md:w-4/6 lg:w-1/2 xl:w-2/5 3xl:w-2/6 py-8 sm:py-16 md:py-18 lg:py-24 bg-white p-12 rounded-3xl shadow-lg">
          <div className="">
            <h2 className="text-center text-2xl md:text-3xl lg:text-5xl mb-4 lg:mb-12 font-roboto leading-9 tracking-tight text-headline">
              Rekisteröidy
            </h2>
          </div>

          <form className="space-y-3 lg:space-y-6" onSubmit={handleSubmit}>
            {/* General error message */}
            {errors.general && (
              <div className="text-red-500 text-sm text-center mb-4">
                {errors.general}
              </div>
            )}

            {/* Email input */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-md lg:text-xl 3xl:ml-20 text-paragraph">
                    Sähköposti
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  required
                  style={
                    errors.email
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid #ffa400" }
                  }
                  className="block w-6/7 md:w-3/4 3xl:w-2/3 text-md lg:text-xl rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {errors.email && (
                <div className="text-red-500 text-sm mt-1 text-center">
                  {errors.email}
                </div>
              )}
            </div>

            {/* Phone input */}
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-md lg:text-xl 3xl:ml-20 text-paragraph">
                    Puhelinnumero
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  autoComplete="tel"
                  required
                  style={
                    errors.phone
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid #ffa400" }
                  }
                  className="block w-6/7 md:w-3/4 3xl:w-2/3 text-md lg:text-xl rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 placeholder:text-sm md:placeholder:text-md focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              {errors.phone && (
                <div className="text-red-500 text-sm mt-1 text-center">
                  {errors.phone}
                </div>
              )}
            </div>

            {/* Password input */}
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-md lg:text-xl 3xl:ml-20 text-paragraph">
                    Salasana
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  style={
                    errors.password
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid #ffa400" }
                  }
                  className="block w-6/7 md:w-3/4 3xl:w-2/3 text-md lg:text-xl rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {errors.password && (
                <div className="text-red-500 text-sm mt-1 text-center">
                  {errors.password}
                </div>
              )}
            </div>

            {/* Confirm Password input */}
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-md lg:text-xl 3xl:ml-20 text-paragraph">
                    Vahvista salasana
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="current-password"
                  required
                  style={
                    errors.confirmPassword
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid #ffa400" }
                  }
                  className="block w-6/7 md:w-3/4 3xl:w-2/3 text-md lg:text-xl rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {errors.confirmPassword && (
                <div className="text-red-500 text-sm mt-1 text-center">
                  {errors.confirmPassword}
                </div>
              )}
            </div>

            <div className="flex items-center justify-center mt-4">
              <input
                id="acceptTerms"
                type="checkbox"
                className="h-4 w-4 text-teal-600 border-gray-300 rounded"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
              <label
                htmlFor="acceptTerms"
                className="ml-2 block text-lg text-gray-900"
              >
                Hyväksyn{" "}
                <button
                  type="button"
                  onClick={handleOpenTerms} // Open the modal
                  className="text-teal-600 underline hover:text-teal-800"
                >
                  käyttöehdot
                </button>
              </label>
            </div>

            {errors.acceptTerms && (
              <div className="text-red-500 text-sm mt-1 text-center">
                {errors.acceptTerms}
              </div>
            )}

            {errors.general && (
              <div className="text-red-500 text-sm text-center mb-4">
                {errors.general}
              </div>
            )}

            {!showTimeshareForm ? (
              <div className="flex justify-center">
                <button
                  type="button"
                  className="flex lg:w-2/4 justify-center rounded-3xl bg-teal-600 px-5 py-3 text-md lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
                  onClick={handleShowForm} // Show the form
                >
                  Lisää omistamasi viikko
                </button>
              </div>
            ) : (
              <div className="my-4">
                <TimeshareInfoInput
                  timeshare={timeshareInput}
                  updateTimeshare={updateTimeshare}
                  setRciBlocked={setRciBlocked}
                />
              </div>
            )}

            {/* Modal for RCI Warning */}
            <Modal
              isVisible={showModal}
              children={
                "Et voi tallettaa osaketta, joka on liitetty RCI-pisteisiin. Suorita RCI-pistepurku ensin."
              }
              onClose={() => setShowModal(false)}
            />

            {/* Submit button */}
            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className="flex w-3/4 lg:w-1/2 justify-center rounded-3xl bg-teal-600 px-5 py-3 text-md lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
              >
                Tunnistaudu
              </button>
            </div>
          </form>
          <TermsModal isVisible={showTermsModal} onClose={handleCloseTerms} />
        </div>
      </div>
    </div>
  );
};

export default Register;
