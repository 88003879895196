import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import remove from "../assets/cross-circle.svg";
import ConfirmationModal from "../components/ConfirmationModal";

const Images = () => {
  const [location, setLocation] = useState(""); // For selected location
  const [propertyName, setPropertyName] = useState(""); // For selected property
  const [images, setImages] = useState([]); // For multiple images
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [imageList, setImageList] = useState([]);
  const [expandedProperty, setExpandedProperty] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/timeshares/images/all`, {
          withCredentials: true,
        });
        const fetchedImageData = response.data || [];

        // Merge searchOptions with fetchedImageData
        const combinedImageData = Object.keys(searchOptions).map(
          (location) => ({
            location,
            properties:
              searchOptions[location]?.map((property) => {
                const existingImages = fetchedImageData.find(
                  (imgData) => imgData.property_name === property
                );
                return {
                  property_name: property,
                  images: existingImages ? existingImages.images : [], // Include images if they exist, otherwise empty array
                };
              }) || [], // Fallback to an empty array if `searchOptions[location]` is undefined
          })
        );

        setImageList(combinedImageData);
        console.log(
          "Fetched and combined images and properties:",
          combinedImageData
        );
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    };

    fetchImageData();
  }, [apiUrl]);

  const handleImageUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("property_name", propertyName);
    formData.append("image_description", description);

    // Append all the selected images to the formData
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${apiUrl}/timeshares/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      // Clear the form after a successful upload
      setLocation("");
      setPropertyName("");
      setImages([]);
      setDescription("");
      setMessage(response.data.message);

      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }

      // Re-fetch the updated image list from the server
      const updatedImagesResponse = await axios.get(
        `${apiUrl}/timeshares/images/all`,
        {
          withCredentials: true,
        }
      );

      const fetchedImageData = updatedImagesResponse.data || [];

      // Combine updated image data with searchOptions again
      const combinedImageData = Object.keys(searchOptions).map((location) => ({
        location,
        properties:
          searchOptions[location]?.map((property) => {
            const existingImages = fetchedImageData.find(
              (imgData) => imgData.property_name === property
            );
            return {
              property_name: property,
              images: existingImages ? existingImages.images : [], // Include images if they exist, otherwise empty array
            };
          }) || [],
      }));

      setImageList(combinedImageData); // Update imageList with the combined data
    } catch (error) {
      console.error("Error uploading images:", error);
      setMessage("Error uploading images");
    } finally {
      setLoading(false);
    }
  };

  const OpenModal = (imageId) => {
    setShowModal(true);
    setImageToDelete(imageId);
  };

  const handlePropertyClick = (propertyName) => {
    setExpandedProperty(
      expandedProperty === propertyName ? null : propertyName
    );
  };

  const handleDeleteImage = async () => {
    if (!imageToDelete) return;

    try {
      await axios.delete(`${apiUrl}/timeshares/images/${imageToDelete}`, {
        withCredentials: true,
      });

      // Re-fetch the updated image list from the server after deletion
      const updatedImagesResponse = await axios.get(
        `${apiUrl}/timeshares/images/all`,
        {
          withCredentials: true,
        }
      );

      const fetchedImageData = updatedImagesResponse.data || [];

      // Combine updated image data with searchOptions again
      const combinedImageData = Object.keys(searchOptions).map((location) => ({
        location,
        properties:
          searchOptions[location]?.map((property) => {
            const existingImages = fetchedImageData.find(
              (imgData) => imgData.property_name === property
            );
            return {
              property_name: property,
              images: existingImages ? existingImages.images : [], // Include images if they exist, otherwise empty array
            };
          }) || [],
      }));

      setImageList(combinedImageData); // Update imageList with the combined data

      setShowModal(false);
      setImageToDelete(null);
      console.log("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  function capitalizeFirstLetter(string) {
    if (!string || typeof string !== "string") {
      return ""; // Return empty string if undefined or not a string
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="bg-transparent flex flex-row justify-center p-4">
      <div className="flex flex-col m-4">
        <h1 className="text-3xl font-bold mb-4 text-center">Lataa kuvia</h1>
        <form
          className="bg-white p-4 rounded-lg shadow-md"
          onSubmit={handleImageUpload}
          encType="multipart/form-data"
        >
          {/* Location Dropdown */}
          <div>
            <label htmlFor="location">Valitse Paikka</label>
            <select
              className="w-full p-2 m-2 border border-gray-300 rounded-lg"
              id="location"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
                setPropertyName(""); // Reset property name when changing location
              }}
              required
            >
              <option value="">Valitse paikka</option>
              {Object.keys(searchOptions).map((loc) => (
                <option key={loc} value={loc}>
                  {loc.charAt(0).toUpperCase() + loc.slice(1)}
                </option>
              ))}
            </select>
          </div>

          {/* Property Name Dropdown */}
          <div>
            <label htmlFor="property_name">Valitse kiinteistöyhtiö</label>
            <select
              className="w-full p-2 m-2 border border-gray-300 rounded-lg"
              id="property_name"
              value={propertyName}
              onChange={(e) => setPropertyName(e.target.value)}
              required
              disabled={!location} // Disable the dropdown until location is selected
            >
              <option value="">Valitse kiinteistöyhtiö</option>
              {location &&
                searchOptions[location]?.map((property) => (
                  <option key={property} value={property}>
                    {property}
                  </option>
                ))}
            </select>
          </div>

          {/* Multiple Image Upload */}
          <div className="my-4">
            <label htmlFor="images" className="">
              Valitse kuva(t)
            </label>
            <input
              className="block w-full p-2 m-2 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:border-teal-600"
              type="file"
              id="images"
              multiple
              onChange={(e) => setImages([...e.target.files])} // Set multiple images
              required
              ref={fileInputRef}
            />
          </div>

          <div>
            <label htmlFor="description">Kuvaus</label>
            <input
              className="block w-full 3xl:w-full rounded-lg border-0 p-2 m-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-secondary ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="flex justify-center">
            {/* Show loading spinner if loading is true */}
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            ) : (
              <button
                type="submit"
                className="bg-teal-600 hover:bg-teal-700 w-full sm:w-auto text-white py-2 px-4 rounded-xl"
              >
                Lataa kuva(t)
              </button>
            )}
          </div>
        </form>

        {message && (
          <div
            className={`p-2 mb-4 text-center rounded-lg ${
              message === "Images uploaded succesfully"
                ? "bg-green-200 text-green-800"
                : "bg-red-200 text-red-800"
            }`}
          >
            {message}
          </div>
        )}
      </div>

      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          prompt={"Haluatko varmasti poistaa kuvan?"}
          onConfirm={handleDeleteImage}
          onCancel={() => setShowModal(false)}
        />
      )}

      <div className="flex flex-col m-4">
        <h1 className="text-3xl font-bold m-4 text-center">Lisätyt kuvat</h1>
        <div className="bg-white p-2 rounded-lg shadow-md">
          {imageList.length > 0 ? (
            imageList.map((locationData) => (
              <div key={locationData.location || Math.random()} className="">
                {/* Add a fallback in case locationData.location is undefined */}
                <h2 className="font-bold text-xl">
                  {capitalizeFirstLetter(
                    locationData.location || "Unknown Location"
                  )}
                </h2>
                {locationData.properties?.map((property) => (
                  <div
                    key={property.property_name || Math.random()}
                    className="my-0"
                  >
                    {/* Property Name and Image Count */}
                    <div
                      className="flex flex-row items-center justify-between cursor-pointer"
                      onClick={() =>
                        handlePropertyClick(property.property_name)
                      }
                    >
                      {/* Add a fallback in case property.property_name is undefined */}
                      <p className="m-2">
                        {property.property_name || "Unknown Property"}
                      </p>
                      <p className="m-2">{property.images.length}</p>
                    </div>

                    {/* Show images or a placeholder message */}
                    {expandedProperty === property.property_name && (
                      <div className="flex flex-wrap grid grid-cols-5 mt-2">
                        {property.images.length > 0 ? (
                          property.images.map((img) => {
                            const imageUrl = `${apiUrl}/${img.image_url}`;
                            return (
                              <div key={img.image_id} className="w-1/2">
                                <button onClick={() => OpenModal(img.image_id)}>
                                  <img
                                    src={remove}
                                    alt="Delete"
                                    className="w-4 h-4"
                                  />
                                </button>
                                <img
                                  src={imageUrl}
                                  alt={img.image_description}
                                  className="w-40 h-auto rounded-md"
                                />
                              </div>
                            );
                          })
                        ) : (
                          <p>No images available for this property.</p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p className="text-center">No properties available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Images;

const searchOptions = {
  airisto: ["Airiston Fregatti", "Airiston Kuunari", "Airiston Kutteri"],
  ellivuori: ["Ellin Loisto I"],
  kivijärvi: ["Hannunkiven Lomakylä"],
  himos: [
    "Himoksen Aurinkopaikka",
    "Himoksen Tähti I",
    "Villas Himos I",
    "Villas Himos II",
    "Villas Himos III",
  ],
  hoilola: ["Onnenvirta II", "Onnenvirta III"],
  ikaalinen: ["Ikaalisten Mäntypiha"],
  imatra: ["Imatra Spa Viikko Oy"],
  kalajoki: [
    "Särkkäin lomaparatiisi I ",
    "Särkkäin lomaparatiisi II",
    "Rantabeach",
    "Kalajoen Keidas",
  ],
  vuokatti: [
    "Katinkullan Golfharju",
    "Katinkullan Hiekkaniemi",
    "Katinkullan Kiinteistöt",
    "Katinkultaniemi",
    "Katinkultaranta",
    "Katinkullan Rantahovi",
    "Katinkulta Residence",
    "Katinkullan Golfpuisto",
    "Katinkulta Spa Lodge 1",
    "Katinkulta Spa Lodge 2",
    "Villas Katinkulta Spa 1",
    "Villas Katinkulta Spa 1 Lodge",
    "Villas Katinkulta Spa 2",
    "Villas Katinkulta Golf Park",
    "Vuokatti Country Club",
    "Vuokatin Kulta-Katti",
    "Vuokatin Lepokatti",
  ],
  koli: ["Kolin Kukkula"],
  kihniö: [
    "Pyhäniemi II",
    "Pyhäniemi III",
    "Pyhäniemi IV",
    "Pyhäniemi V",
    "Pyhäniemi VI",
    "Pyhäniemi VII",
    "Pyhäniemi VIII",
  ],
  kuortane: ["Kuortaneen Liikuntahotelli"],
  kuusamo: [
    "Kuusamon Lampitropiikki",
    "Kuusamon Tähti 1",
    "Kuusamon Lomaparatiisi",
    "Kuusamon Rantatropiikki",
    "Kuusamon Rantatropiikki 2",
    "Petäjälampi 6 Lodge",
    "Petäjälammenranta 7 Lodge",
  ],
  laukaa: ["Pitkäniemi III"],
  levi: [
    "Levi-Rakkavaara Club 1",
    "Rakkavaara Club Int. Ltd",
    "Abgott",
    "Aruudenia",
  ],
  naantali: ["Naantalin kylpyläranta", "Sunborn Vacation Club 1"],
  punkaharju: ["Hiekkaharju 1", "Hiekkaharju 2"],
  pyhä: ["Onninpyhä", "Pyhänhovi", "Pyhä HolySuites"],
  rönnäs: ["Rönnäs Country Club"],
  ruka: [
    "Rukan Lomakylä I",
    "RukaVillage Suites 1",
    "Kuusamon Pulkkajärvi 3",
    "Kuusamon Pulkkajärvi 4",
    "Kuusamon Pulkkajärvi 5",
    "Kuusamon Pulkkajärvi 6",
  ],
  saimaa: [
    "Anttilankaari 6",
    "Anttilankaari 8",
    "Anttilankaari 10",
    "Vipelentie 35",
    "Saimaanranta",
    "Saimaanranta 2",
    "Saimaanranta 3",
    "Saimaanrantapuisto",
    "Saimaan Keskuspuisto Lodge",
    "Saimaa Pearl Lodge 1",
    "Saimaa Spa Lodge 1",
    "Saimaa Spa Lodge 2",
    "Saimaa Spa Lodge 3",
  ],
  salla: [
    "Sallas huoneistot",
    "Sallan tähti",
    "Sallatunturin Kelorinne",
    "Sallan Eraustähti",
    "Villas Sallatunturi 1",
    "Villas Sallatunturi 2",
  ],
  saariselkä: [
    "Kermikkä",
    "Siulaselkä",
    "Kelotirro",
    "Riekonraito",
    "Laavutieva",
    "Tirrolampi",
    "Nilihonka",
    "Saariselkä Spa Lodge 2",
    "Kelotähti 1 Lodge",
    "Ruskarinne",
  ],
  tahko: [
    "Tahkotime",
    "Leppätahko",
    "Nilsiän Rentotahko",
    "Spa Suites Black",
    "Spa Suites White",
  ],
  tampere: [
    "Näsijärven Kimallus",
    "Lapiinniemi I",
    "Lapinniemi II",
    "Lapinniemi III",
    "Lapinniemi IV",
    "Lapinniemi V",
    "Lapinniemi VI",
    "Lapinniemi VII",
    "Lapinniemi VIII",
    "Lapinniemi IX",
    "Lapinniemi X",
    "Lapinniemi XI",
    "Lapinniemi XII",
    "Lapinniemi XIII",
    "Lapinniemi XIV",
    "Lapinniemi XV",
    "Lapinniemi XVI",
    "Lapinniemi XVII",
    "Lapinniemi XVIII",
    "Lapinniemi XIX",
    "Lapinniemi XX",
  ],
  turku: ["Caribia Spa Lodge 1", "HC Villas Turun Caribia"],
  vierumäki: [
    "HC Villas Vierumäki 1",
    "HC Villas Vierumäki 2",
    "Vierumäki Golf Resort",
  ],
  ylläs: [
    "Kolarin Siepakka",
    "Kesänki",
    "Kuer",
    "Muiro",
    "Musko",
    "Mokko",
    "Ylläksen Rautamajat",
    "Ylläs Saaga",
    "Ylläs viikko 1",
    "Ylläksen lomaviikot",
  ],
  ähtäri: ["Ähtärin Lomakylä", "Moksunhonka 1"],
  ulkomaat: [
    "Jardin Amadores",
    "Playa Amadores",
    "Puerto Calma",
    "Sol Amadores",
    "Vista Amadores",
    "Åre",
  ],
};
