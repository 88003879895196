const PaymentCancel = () => {
  return (
    <div className="flex flex-col items-center justify-start sm:h-screen p-4">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full sm:w-3/4 md:w-1/2 lg:w-1/3 flex flex-col items-center">
        <p className="text-xl sm:text-2xl font-semibold text-red-500 mb-4">
          Maksu epäonnistui
        </p>
        <p className="text-base sm:text-lg text-gray-600 mb-6 text-center">
          Maksu epäonnistui. Palaa etusivulle
        </p>
        <button
          className="w-full sm:w-3/4 rounded-3xl bg-teal-600 px-4 sm:px-5 py-2 sm:py-3 text-base sm:text-lg lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
          onClick={() => window.location.replace("/")}
        >
          Etusivulle
        </button>
      </div>
    </div>
  );
};

export default PaymentCancel;
