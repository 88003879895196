import { useState } from "react";
import axios from "axios";
import Modal from "../components/Modal";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const apiurl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiurl}/auth/forgot-password`, {
        email,
      });
      if (response.data.exists) {
        setModalMessage("Salasanan nollauslinkki on lähetetty sähköpostiisi.");
      } else {
        setModalMessage("Emme löytäneet käyttäjää antamallasi sähköpostilla.");
      }
    } catch (error) {
      setModalMessage("Virhe, yritä uudelleen.");
    } finally {
      setShowModal(true); // Show modal with the result message
    }
  };

  const closeModal = () => setShowModal(false);

  return (
    <div className="w-screen flex flex-col items-center">
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col justify-center items-center bg-white rounded-lg shadow-lg p-4 w-5/6 md:w-3/5 xl:w-1/2 2xl:w-1/3 3xl:w-1/4 h-64 my-24">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full items-center"
          >
            <p className="text-xl font-roboto leading-6 text-black m-4">
              Unohditko salasanasi?
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Anna sähköpostiosoitteesi"
              className="p-2 m-4 w-5/6 md:w-3/4 rounded-xl border-0 text-black shadow-sm ring-2 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-teal-700 focus:outline-none"
              required
            />
            <button
              className="w-3/4 lg:w-1/2 rounded-3xl bg-teal-600 px-5 py-3 m-4 text-lg font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
              type="submit"
            >
              Lähetä nollauslinkki
            </button>
          </form>

          {/* Modal Component */}
          {showModal && (
            <Modal isVisible={showModal} onClose={closeModal}>
              <p>{modalMessage}</p>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
