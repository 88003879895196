import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "../components/Modal";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { token } = useParams(); // Retrieve token from URL
  const navigate = useNavigate(); // Hook for navigation

  const apiurl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password validation
    if (password.length < 8) {
      setModalMessage("Salasanan on oltava vähintään 8 merkkiä pitkä.");
      setShowModal(true);
      return;
    }

    try {
      await axios.post(`${apiurl}/auth/reset-password`, {
        token,
        newPassword: password,
      });
      setModalMessage("Salasana vaihdettu onnistuneesti.");
    } catch (error) {
      setModalMessage("Virhe, yritä uudelleen.");
    } finally {
      setShowModal(true);
    }
  };

  const closeModal = () => setShowModal(false);

  const handleRedirect = () => {
    setShowModal(false);
    navigate("/kirjaudu"); // Redirect to login page
  };

  return (
    <div className="w-screen flex flex-col items-center">
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col justify-center items-center bg-white rounded-lg shadow-lg p-4 w-5/6 md:w-3/5 xl:w-1/2 2xl:w-1/3 3xl:w-1/4 h-64 my-24">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full items-center"
          >
            <p className="text-xl font-roboto leading-6 text-black m-4">
              Anna uusi salasana
            </p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Salasana"
              className="p-2 m-4 w-5/6 md:w-3/4 rounded-xl border-0 text-black shadow-sm ring-2 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-teal-700 focus:outline-none"
              required
            />
            <button
              className="w-3/4 lg:w-1/2 rounded-3xl bg-teal-600 px-5 py-3 m-4 text-lg font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
              type="submit"
            >
              Vaihda salasana
            </button>
          </form>

          {/* Modal Component */}
          {showModal && (
            <Modal isVisible={showModal} onClose={closeModal}>
              <p>{modalMessage}</p>
              {modalMessage === "Salasana vaihdettu onnistuneesti." && (
                <button
                  onClick={handleRedirect}
                  className="mt-4 px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700"
                >
                  Siirry kirjautumiseen
                </button>
              )}
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
