import logo from "../../assets/logo_valk.png";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogout = async () => {
    try {
      await axios.get(`${apiUrl}/auth/logout`, {
        withCredentials: true,
      });
      logout();
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className="flex flex-col w-screen">
      <div className="bg-teal-700 w-full h-64 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <img src={logo} alt="logo" className="w-64 md:w-80 my-2" />

          {/* Hide the links on small screens */}
          <div className="hidden md:flex md:flex-row md:space-x-4 items-center">
            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/"
                className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Etusivu
              </a>
            </div>

            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/hinnat"
                className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Hinnasto
              </a>
            </div>

            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/laskin"
                className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Lomakrediittilaskuri
              </a>
            </div>

            <div className="flex items-center rounded-2xl h-12 my-4">
              <a
                href="/kayttoehdot"
                className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
              >
                Käyttöehdot
              </a>
            </div>

            {user ? (
              <div className="flex items-center">
                <div className="flex items-center rounded-2xl h-12 my-4">
                  <a
                    href="/profiili"
                    className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
                  >
                    Oma sivu
                  </a>
                </div>

                <div className="flex items-center rounded-2xl h-12 my-4">
                  <a
                    href="/Lompakko"
                    className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 transition duration-200 hover:underline"
                  >
                    Lompakko {user.credits}
                  </a>
                </div>

                <button
                  className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 hover:underline transition duration-200 my-2"
                  onClick={handleLogout}
                >
                  Kirjaudu ulos
                </button>
              </div>
            ) : (
              <button
                className="text-white px-4 py-2 rounded-2xl hover:bg-teal-700 hover:underline transition duration-200 my-2"
                onClick={() => navigate("/kirjaudu")}
              >
                Kirjaudu sisään
              </button>
            )}
          </div>
          <hr className="border-t border-gray-300 my-4 w-screen" />
          <p className="text-white text-xs">
            Copyright © Lomahuuto.fi All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
