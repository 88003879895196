import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useAuth } from "../context/authContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    general: "",
  });

  const { login } = useAuth();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/user`, {
          withCredentials: true,
        });

        if (response.data.isAuthenticated) {
          login(response.data.user);
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      }
    };

    checkLoggedIn();
  }, [navigate, login]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let hasErrors = false;

    // Validate email
    if (email === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Käyttäjätunnus tai sähköposti on pakollinen",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    // Validate password
    if (password === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Salasana on pakollinen",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    // If there are any errors, prevent submission
    if (hasErrors) return;

    const data = {
      email,
      password,
    };

    try {
      const response = await axios.post(`${apiUrl}/auth/login`, data, {
        withCredentials: true,
      });

      if (response.status === 200) {
        login(response.data.user);

        if (response.data.redirectTo) {
          // Navigate to the desired page
          navigate(response.data.redirectTo);

          // After navigation, fetch user state to ensure logged-in state is reflected
          const userResponse = await axios.get(`${apiUrl}/auth/user`, {
            withCredentials: true,
          });

          if (userResponse.data.isAuthenticated) {
            login(userResponse.data.user); // Update the user state
          }
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Incorrect email or password
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Väärä sähköpostiosoite tai salasana. Yritä uudelleen.",
        }));
      } else if (error.response && error.response.status === 404) {
        // User not registered
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Käyttäjää ei löytynyt. Rekisteröidy ensin.",
        }));
      } else {
        // Other errors (network, etc.)
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
        }));
      }
    }
  };

  return (
    <div className="bg-transparent">
      <div className="flex justify-center items-center h-full">
        <div className="w-full m-3 sm:m-6 md:m-12 sm:w-4/6 md:w-4/6 lg:w-1/2 xl:w-2/5 3xl:w-1/5 py-8 sm:py-16 md:py-18 lg:py-24 bg-white p-2 lg:p-12 rounded-3xl shadow-lg">
          <div className="">
            <h2 className="text-center text-2xl md:text-3xl lg:text-5xl mb-10 font-roboto leading-9 tracking-tight text-headline">
              Kirjaudu sisään
            </h2>
          </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* General error message */}
            {errors.general && (
              <div className="text-red-600 text-center mb-4">
                {errors.general}
              </div>
            )}

            {/* Email field */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-md lg:text-xl 3xl:ml-20 text-paragraph">
                    Sähköposti
                  </p>
                </div>
              </label>

              <div className="flex justify-center mt-2">
                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  required
                  style={
                    errors.email
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid teal-600" }
                  }
                  className="block w-5/6 md:w-3/4 3xl:w-2/3 text-md lg:text-xl rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 placeholder:text-sm md:placeholder:text-md focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="Sähköposti"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {errors.email && (
                <p className="text-red-600 text-sm mt-2 text-center">
                  {errors.email}
                </p>
              )}
            </div>

            {/* Password field */}
            <div className="">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-md lg:text-xl 3xl:ml-20 text-paragraph">
                    Salasana
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  style={
                    errors.password
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid teal-600" }
                  }
                  className="block p-2 w-5/6 md:w-3/4 3xl:w-2/3 text-md lg:text-xl rounded-xl border-0 py-1.5 text-black shadow-sm ring-2 ring-inset ring-teal-600 ring-secondary placeholder:text-gray-400 placeholder:text-sm md:placeholder:text-md focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="Salasana"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {errors.password && (
                <p className="text-red-600 text-sm mt-2 text-center">
                  {errors.password}
                </p>
              )}
            </div>

            {/* Submit button */}
            <div className="flex justify-center">
              <button
                type="submit"
                className="flex w-3/4 lg:w-1/2 justify-center rounded-3xl bg-teal-600 px-5 py-3 text-md lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
              >
                Kirjaudu sisään
              </button>
            </div>

            <div className="text-sm flex justify-center">
              <a
                href="/unohditko-salasanasi"
                className="font-roboto text-md md:text-md lg:text-xl text-paragraph focus:text-orange hover:underline"
              >
                Unohditko salasanasi?
              </a>
            </div>

            {/* New user registration link */}
            <div className="text-sm flex justify-center">
              <a
                href="/rekisteroidy"
                className="font-roboto text-md md:text-md lg:text-xl text-paragraph focus:text-orange hover:underline"
              >
                Uusi käyttäjä? Rekisteröidy tästä
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
