import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Prices from "./pages/Prices";
import Profile from "./pages/Profile";
import CalculatorPage from "./pages/CalculatorPage";
import Terms from "./pages/Terms";
import Layout from "./components/layout";
import Users from "./pages/Users";
import Images from "./pages/Images";
import Links from "./pages/Links";
import Support from "./pages/Support";
import Welcome from "./pages/Welcome";
import ReNewMembership from "./pages/ReNewMembership";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCancel from "./pages/PaymentCancel";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Wallet from "./pages/Wallet";
import { AuthProvider, useAuth } from "./context/authContext";
import ProtectedRoute from "./components/ProtectedRoute";

const AppRoutes = () => {
  const { user } = useAuth();

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/kirjaudu" element={<Login />} />
      <Route path="/rekisteroidy" element={<Register />} />
      <Route path="/hinnat" element={<Prices />} />
      <Route path="/laskin" element={<CalculatorPage />} />
      <Route path="/kayttoehdot" element={<Terms />} />
      <Route path="/tuki" element={<Support />} />
      <Route path="/tervetuloa" element={<Welcome />} />
      <Route path="/kiitos" element={<PaymentSuccess />} />
      <Route path="/maksuvirhe" element={<PaymentCancel />} />
      <Route path="/uusi-jasenyys" element={<ReNewMembership />} />
      <Route path="/unohditko-salasanasi" element={<ForgotPassword />} />
      <Route path="/vaihda-salasana/:token" element={<ResetPassword />} />
      <Route path="/lompakko" element={<Wallet />} />

      {/* Protected Routes - Only accessible with active membership */}
      <Route
        path="/profiili"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />

      {/* Admin Routes - Only accessible to admins */}
      {user?.isAdmin && (
        <>
          <Route
            path="/kayttajat"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/kuvat"
            element={
              <ProtectedRoute>
                <Images />
              </ProtectedRoute>
            }
          />
          <Route
            path="/linkit"
            element={
              <ProtectedRoute>
                <Links />
              </ProtectedRoute>
            }
          />
        </>
      )}
    </Routes>
  );
};

const App = () => {
  return (
    <div className="w-full overflow-x-hidden">
      <AuthProvider>
        <Router>
          <Layout>
            <AppRoutes />
          </Layout>
        </Router>
      </AuthProvider>
    </div>
  );
};

export default App;
