import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for user data
  const apiUrl = process.env.REACT_APP_API_URL;

  const login = useCallback((userData) => {
    setUser(userData);
    setLoading(false); // Stop loading once user is set
  }, []);

  const logout = useCallback(async () => {
    try {
      await axios.post(`${apiUrl}/auth/logout`, {}, { withCredentials: true });
      setUser(null);
    } catch (error) {
      console.error("Error during logout:", error);
    }
    setLoading(false); // Stop loading if user logs out
  }, [apiUrl]);

  const updateUserCredits = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/user`, {
        withCredentials: true,
      });
      if (response.data.isAuthenticated) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error("Error updating user credits:", error);
    }
  }, [apiUrl]);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true); // Start loading before fetching
      try {
        const response = await axios.get(`${apiUrl}/auth/user`, {
          withCredentials: true,
        });
        if (response.data.isAuthenticated) {
          login(response.data.user);
        } else {
          console.log("User is not authenticated on initial load");
          setUser(null); // Explicitly set user to null
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        setUser(null); // Clear user state if there's an error
      } finally {
        setLoading(false); // Stop loading once fetch is complete
      }
    };

    fetchUser();
  }, [apiUrl, login]);

  return (
    <AuthContext.Provider
      value={{ user, loading, login, logout, updateUserCredits }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
