import { differenceInMonths, differenceInWeeks } from "date-fns";

export const calculateAdjustedCredits = (startDate, credits) => {
  const today = new Date();
  const start = new Date(startDate);

  if (isNaN(start) || !credits) {
    console.error("Invalid date or credits for calculating adjusted credits");
    return credits || 0;
  }

  const monthsDifference = differenceInMonths(start, today);
  const weeksDifference = differenceInWeeks(start, today);

  let adjustedCredits = credits;

  if (weeksDifference < 4) {
    adjustedCredits *= 0.3; // 30% of the credits
  } else if (monthsDifference < 3) {
    adjustedCredits *= 0.5; // 50% of the credits
  } else if (monthsDifference < 10) {
    adjustedCredits = adjustedCredits; // 100% of the credits
  } else if (monthsDifference >= 10) {
    adjustedCredits *= 1.2; // 20% more credits
  }

  return Math.round(adjustedCredits);
};
