import { ReactComponent as Map } from "../assets/finland.svg";
import { useState } from "react";

const properties = [
  { name: "Airisto", x: 70, y: 735, details: "asdasdasdasdasdasdasdsasddasd" },
  { name: "Himos", x: 200, y: 640 },
  { name: "Ellivuori", x: 125, y: 670 },
  { name: "Kivijärvi", x: 170, y: 560 },
  { name: "Hoilola", x: 358, y: 610 },
  { name: "Ikaalinen", x: 110, y: 630 },
  { name: "Imatra", x: 293, y: 702 },
  { name: "Kalajoki", x: 155, y: 465 },
  { name: "Vuokatti", x: 290, y: 480 },
  { name: "Koli", x: 330, y: 560 },
  { name: "Kihniö", x: 115, y: 605 },
  { name: "Kuortane", x: 120, y: 570 },
  { name: "Kuusamo", x: 320, y: 350 },
  { name: "Laukaa", x: 220, y: 585 },
  { name: "Levi", x: 160, y: 190 },
  { name: "Naantali", x: 70, y: 720 },
  { name: "Punkaharju", x: 320, y: 660 },
  { name: "Pyhä", x: 240, y: 250 },
  { name: "Rönnäs", x: 200, y: 750 },
  { name: "Ruka", x: 320, y: 335 },
  { name: "Saimaa", x: 290, y: 690 },
  { name: "Salla", x: 290, y: 285 },
  { name: "Saariselkä", x: 250, y: 165 },
  { name: "Tahko", x: 260, y: 540 },
  { name: "Tampere", x: 150, y: 655 },
  { name: "Turku", x: 80, y: 745 },
  { name: "Vierumäki", x: 200, y: 695 },
  { name: "Ylläs", x: 150, y: 210 },
  { name: "Ähtäri", x: 170, y: 590 },
];

const TimeshareMap = () => {
  const [hoveredProperty, setHoveredProperty] = useState(null);

  return (
    <div className="relative flex justify-center items-start">
      <div className="flex-1">
        <svg
          className="w-full md:w-[60%] h-auto"
          viewBox="0 0 500 800"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Render the imported SVG map */}
          <Map />

          {/* Add pins dynamically */}
          {properties.map((property, index) => (
            <g
              key={index}
              transform={`translate(${property.x}, ${property.y})`}
              className="pin"
              onMouseEnter={() => setHoveredProperty(index)}
              onMouseLeave={() => setHoveredProperty(null)}
            >
              {/* Circle representing the pin */}
              <circle cx="0" cy="0" r="5" fill="red" />

              {/* Text label - Always visible */}
              <text x="10" y="5" fill="black">
                {property.name}
              </text>
            </g>
          ))}
        </svg>
      </div>

      {/* Absolutely positioned description box, shown only when hovering over a pin */}
      {/* <div
        className={`absolute top-0 right-0 bg-white rounded-lg shadow-lg p-4 ${
          hoveredProperty === null
            ? "opacity-0 pointer-events-none"
            : "opacity-100"
        }`}
      >
        {hoveredProperty !== null && (
          <>
            <h2 className="text-xl font-semibold mb-2">
              {properties[hoveredProperty].name}
            </h2>
            <p className="text-sm text-gray-600">
              {properties[hoveredProperty].details}
            </p>
          </>
        )}
      </div> */}
    </div>
  );
};

export default TimeshareMap;
