import axios from "axios";
import { useEffect, useState } from "react";

const Wallet = () => {
  const [creditData, setCreditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const response = await axios.get(`${apiUrl}/auth/wallet`);
        setCreditData(response.data.credits);
        console.log("response", response);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCredits();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  // Calculate total credits
  const totalCredits = creditData.reduce(
    (total, credit) => total + credit.amount,
    0
  );

  // Helper function to format date ranges to dd.mm.yyyy format
  function formatDateRange(source) {
    return source.replace(
      /(\d{1,2})\/(\d{1,2})\/(\d{4})/g,
      (_, month, day, year) =>
        `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`
    );
  }

  return (
    <div className="wallet-container max-w-4xl mx-auto overflow-x-auto">
      <h1 className="text-2xl font-bold mb-4">
        Lompakkosi: {totalCredits} lomakrediittiä
      </h1>
      {creditData.length === 0 ? (
        <p>Sinulla ei ole krediittejä lompakossasi.</p>
      ) : (
        <>
          <table className="w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-teal-600 text-white">
                <th className="p-3 font-semibold text-left">Krediitit saatu</th>
                <th className="p-3 font-semibold text-left">
                  Krediitit vanhenee
                </th>
                <th className="p-3 font-semibold text-left">Jäljellä</th>
                <th className="p-3 font-semibold text-left">Lähde</th>
              </tr>
            </thead>
            <tbody>
              {creditData.map((credit) => (
                <tr
                  key={credit.id}
                  className="border-t border-gray-200 bg-white even:bg-gray-100"
                >
                  <td className="p-3">
                    {new Date(credit.dateAcquired).toLocaleDateString("fi-FI")}
                  </td>
                  <td className="p-3">
                    {new Date(credit.expirationDate).toLocaleDateString(
                      "fi-FI"
                    )}
                  </td>
                  <td className="p-3">{credit.amount}</td>
                  <td className="p-3">
                    {credit.source && credit.source.includes("-")
                      ? formatDateRange(credit.source)
                      : credit.source}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Wallet;
