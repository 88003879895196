import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authContext";

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if user data is available and if the membership is inactive or undefined
    if (user && user.membership) {
      // If membership is inactive and not trying to access renewal page
      if (
        user.membership.active === 0 &&
        location.pathname !== "/tervetuloa" &&
        location.pathname !== "/uusi-jasenyys"
      ) {
        console.log("redirecting to /tervetuloa");
        navigate("/tervetuloa"); // Redirect to welcome if membership isn't active
      }
    }
  }, [user, navigate, location]);

  // Allow access to protected route if membership is active
  if (
    user?.membership?.active === 1 ||
    location.pathname === "/uusi-jasenyys"
  ) {
    return children;
  }

  // Optional: Render a loading state if user data is still being fetched
  return <p>Loading...</p>;
};

export default ProtectedRoute;
