import { useState, useEffect } from "react";
import axios from "axios";

const Links = () => {
  const [location, setLocation] = useState("");
  const [homepageUrl, setHomepageUrl] = useState("");
  const [message, setMessage] = useState("");
  const [existingLinks, setExistingLinks] = useState([]); // To store locations with links

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchExistingLinks = async () => {
      try {
        const response = await axios.get(`${apiUrl}/timeshares/links`);
        console.log("response", response);

        setExistingLinks(response.data); // Assume API returns an array of locations with links
      } catch (error) {
        console.error("Error fetching existing links:", error);
      }
    };

    fetchExistingLinks();
  }, [apiUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/timeshares/add-link`, {
        location: location,
        homepage_url: homepageUrl,
      });

      setMessage(response.data.message);
      setLocation("");
      setHomepageUrl("");
      setExistingLinks((prevLinks) => [...prevLinks, location]); // Update existing links list
    } catch (error) {
      setMessage("Error adding link: " + error.response.data.message);
    }
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col items-center p-4">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-center bg-white p-8 rounded-lg shadow-md"
      >
        <div>
          <label>Kohde</label>
          <select
            className="block w-full 3xl:w-full rounded-lg border-0 p-2 m-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-secondary ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          >
            <option value="">Valitse Kohde</option>
            {locationOptions.map((loc) => (
              <option
                key={loc}
                value={loc}
                disabled={existingLinks.includes(loc)} // Disable if link already exists
              >
                {loc.charAt(0).toUpperCase() + loc.slice(1)}{" "}
                {existingLinks.includes(loc) ? "(Linkki lisätty)" : ""}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Linkki</label>
          <input
            className="block w-full 3xl:w-full rounded-lg border-0 p-2 m-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-secondary ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            type="text"
            value={homepageUrl}
            onChange={(e) => setHomepageUrl(e.target.value)}
            required
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-teal-600 hover:bg-teal-700 w-full sm:w-auto text-white py-2 px-4 m-2 rounded-xl"
          >
            Lisää linkki
          </button>
        </div>
        {message && <p>{message}</p>}
      </form>

      {/* Display locations with links */}
      <div className="mt-4 bg-white rounded-lg p-4 shadow-lg">
        <h2 className="text-lg font-semibold">Kohteet, joilla on jo linkki:</h2>
        <ul className="list-disc list-inside">
          {existingLinks.map((link, index) => (
            <li key={index} className="text-gray-700">
              {link.location.charAt(0).toUpperCase() + link.location.slice(1)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Links;

const locationOptions = [
  "airisto",
  "ellivuori",
  "kivijärvi",
  "himos",
  "hoilola",
  "ikaalinen",
  "imatra",
  "kalajoki",
  "vuokatti",
  "koli",
  "kihniö",
  "kuortane",
  "kuusamo",
  "laukaa",
  "levi",
  "naantali",
  "punkaharju",
  "pyhä",
  "rönnäs",
  "ruka",
  "saimaa",
  "salla",
  "saariselkä",
  "tahko",
  "tampere",
  "turku",
  "vierumäki",
  "ylläs",
  "ähtäri",
  "ulkomaat",
];
